import {
  createStore,
  createEvent,
  createEffect,
  sample,
  forward,
  combine,
  split,
} from 'effector';
import { v4 as uuidv4 } from 'uuid';
import http from 'src/logic/http';
import { stores as addressStores } from 'src/effector/checkout/address';
import {
  stores as orderStores,
  actions as orderActions,
} from 'src/effector/order';
import * as api from 'src/logic/api';

const selectStripe = createEvent();
const selectPaypal = createEvent();
const selectCashapp = createEvent();

const $isPurchasing = createStore(false);

const setStripe = createEvent();
const $stripe = createStore({}).on(setStripe, (_, s) => s);

const setPaymentId = createEvent();
const $paymentId = createStore('').on(setPaymentId, (_, pi) => pi);

const initStripe = createEvent();
const setStripeElements = createEvent();
const $stripeElements = createStore({}).on(setStripeElements, (_, e) => e);

const setPaymentFlowType = createEvent();
const $paymentFlowType = createStore({}).on(
  setPaymentFlowType,
  (_, data) => data,
);

const loadAfterpayJs = createEvent();
const setLoadedAfterpayJs = createEvent();
const $afterpayJsLoaded = createStore(false).on(
  setLoadedAfterpayJs,
  () => true,
);
const loadAfterpayJsFx = createEffect().use(({ test_mode }) => {
  console.log('test_mode', test_mode);
  const afterpay_js_url = test_mode
    ? `https://portal.sandbox.afterpay.com/afterpay.js`
    : `https://portal.afterpay.com/afterpay.js`;

  $.getScript(
    afterpay_js_url,
    (_, textStatus) => textStatus === 'success' && setLoadedAfterpayJs(),
  );
});

const initSyfPaymentMethod = createEvent();
const $syfData = createStore({});
const completeSyfPayment = createEvent();

const goToLocationFx = createEffect().use(
  ({ location }) => location && window.location.replace(location),
);

const getSyfCheckoutTokenFx = createEffect().use(async () => {
  const { data } = await http.get(
    `${window.location.origin}/api/v1/synchronys/init_syf_payment`,
  );

  return data;
});

const completeSyfPaymentFx = createEffect().use(async () => {
  const { data } = await http.post(
    `${window.location.origin}/api/v1/synchronys/complete_payment`,
  );

  return data;
});

const getPayloadFx = createEffect().use(async params => {
  const {
    data,
  } = await http.get(
    `${window.location.origin}/api/v1/orders/payment_payload`,
    { params },
  );

  return data;
});

const modalClosureHandler = event => {
  if (
    (typeof event.data == 'string' || typeof event.data == 'object') &&
    (event.data == 'Close Model' ||
      event.data == 'Return To Merchant Shipping' ||
      event.data == 'Close' ||
      event.data.action == 'setPayCloseModal')
  ) {
    completeSyfPayment();
  }
};

const subsrctibeToSynchronysEventsFx = createEffect().use(() => {
  window.addEventListener('message', modalClosureHandler);
});

const closePaypairModal = createEvent();
const closePaypairModalFx = createEffect().use(() => window.Paypair.close());

const onPaypairEventFired = createEvent();

const notifyAboutOnLeaseSignedError = createEvent();
const notifyAboutOnLeaseSignedErrorFx = createEffect().use(
  api.notifyAboutSomething,
);

sample({
  clock: notifyAboutOnLeaseSignedError,
  target: notifyAboutOnLeaseSignedErrorFx,
});

const storeInformationAboutOnLeaseSignedCallback = createEvent();
const storeInformationAboutOnLeaseSignedCallbackFx = createEffect().use(
  api.createAhoyEvent,
);

sample({
  clock: storeInformationAboutOnLeaseSignedCallback,
  target: storeInformationAboutOnLeaseSignedCallbackFx,
});

const initPaypairFx = createEffect().use(
  ({ result: { paypair_payload, current_visit_id, is_promo_applied } }) => {
    const options = {
      orderDetails: {
        ...paypair_payload,
        visitId: current_visit_id,
        promoApplied: is_promo_applied,
      },
      onEventFired: onPaypairEventFired,
      onLeaseSigned: data => {
        try {
          const form = document.createElement('form');
          form.action = '/paypair/update';
          form.method = 'POST';

          const dataToSend = [
            {
              value: data.app_id,
              name: 'token',
            },
            {
              value: data.provider,
              name: 'lender_name',
            },
            {
              value: paypair_payload.orderId,
              name: 'order_number',
            },
            {
              value: data.zip_code,
              name: 'zip_code',
            },
            {
              value: data.state,
              name: 'state',
            },
          ]
            .filter(({ value }) => Boolean(value))
            .forEach(({ value, name }) => {
              const input = document.createElement('input');
              input.setAttribute('type', 'hidden');
              input.setAttribute('name', name);
              input.setAttribute('value', value);
              form.appendChild(input);
            });

          setTimeout(() => {
            document.body.appendChild(form);
            form.submit();
          }, 0);
        } catch (e) {
          notifyAboutOnLeaseSignedError({ msg: e.stack });
        }
      },
    };

    window.Paypair.open(options);
  },
);

const initAffirmFx = createEffect().use(({ result: { affirm_payload } }) => {
  affirm.checkout(affirm_payload);
  affirm.checkout.open();
});

const initLender = createEvent();
const initAffirm = initLender.prepend(() => ({ type: 'affirm' }));
const initPaypair = initLender.prepend(lender => ({ type: 'paypair', lender }));

forward({
  from: initLender,
  to: getPayloadFx,
});

split({
  source: getPayloadFx.done,
  match: ({ params: { type } }) => type,
  cases: {
    paypair: initPaypairFx,
    affirm: initAffirmFx,
  },
});

const initStripeFx = createEffect().use(({ stripe_pk, paymentId }) => {
  const stripe = window.Stripe(stripe_pk);

  setStripe(stripe);
  setPaymentId(paymentId);
});

const initGoogleAndAppleElementsFx = createEffect().use(order => {
  window.SolidusStripe.CartPageCheckout.prototype.setUpPaymentRequest = function() {
    const config = this.config.payment_request;

    const paymentRequest = this.stripe.paymentRequest({
      country: config.country,
      currency: config.currency,
      total: {
        label: config.label,
        amount: order.deciaml_total_number,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    const prButton = this.elements.create('paymentRequestButton', {
      paymentRequest: paymentRequest,
      style: {
        paymentRequestButton: {
          height: '70px',
        },
      },
    });

    const onButtonMount = function(result) {
      const id = 'payment-request-button';

      if (result) {
        prButton.mount('#' + id);
        setPaymentFlowType(result);
      } else {
        console.log('Google/Apple payments not available');
        document.getElementById(id).style.display = 'none';
      }
      if (typeof this.onPrButtonMounted === 'function') {
        this.onPrButtonMounted(id, result);
      }
    };
    paymentRequest.canMakePayment().then(onButtonMount.bind(this));

    const onPrPaymentMethod = function(result) {
      this.errorElement.text('').hide();
      this.onPrPayment(result);
    };

    paymentRequest.on('paymentmethod', onPrPaymentMethod.bind(this));
  };
});

const initGoogleAndAppleConfigFx = createEffect().use(() =>
  new window.SolidusStripe.CartPageCheckout().init(),
);

const initStripeElementsFx = createEffect().use(stripe => {
  const elements = stripe.elements();
  const style = {
    base: {
      fontSize: '16px',
      fontWeight: 300,

      '::placeholder': {
        color: '#CFD7E0',
      },
    },
  };

  const setOutcome = (result, fieldId) => {
    const errorElement = document.getElementById(`${fieldId}-error`);
    const containerElement = document.getElementById(`${fieldId}-element`);

    errorElement.classList.add('hidden');
    containerElement.classList.remove('!border-red');

    if (result.error) {
      console.log(result);
      errorElement.textContent = result.error.message;
      errorElement.classList.remove('hidden');
      containerElement.classList.add('!border-red');
    }
  };

  const inputNames = ['cardNumber', 'cardExpiry', 'cardCvc'];
  const placeholders = {
    cardNumber: '* Card Number',
    cardExpiry: '* Exp. (MM/YY)',
    cardCvc: '* CVC',
  };
  const stripeElements = inputNames.reduce((acc, name) => {
    const elem = elements.create(name, {
      style,
      placeholder: placeholders[name],
    });
    elem.mount(`#${name}-element`);
    elem.on('change', e => setOutcome(e, name));
    return { ...acc, [name]: elem };
  }, {});
  setStripeElements(stripeElements);
});

const purchaseWithStripe = createEvent();

const createTokenFx = createEffect().use(
  ({ cardNumberElement, options, stripe }) =>
    stripe.createToken(cardNumberElement, options),
);

const showCardNumberErrorFx = createEffect().use(message => {
  const errorElement = document.getElementById('cardNumber-error');
  errorElement.textContent = message;
  errorElement.classList.add('visible');
});

const createFormToSendFx = createEffect().use(({ paymentId, token }) => {
  const form = document.getElementById('checkout_form_payment');

  const dataToSend = [
    {
      value: token.id,
      name: `payment_source[${paymentId}][gateway_payment_profile_id]`,
    },
    {
      value: token.card.last4,
      name: `payment_source[${paymentId}][last_digits]`,
    },
    {
      value: token.card.exp_month,
      name: `payment_source[${paymentId}][month]`,
    },
    {
      value: token.card.exp_year,
      name: `payment_source[${paymentId}][year]`,
    },
    {
      value: paymentId,
      name: `order[payments_attributes][][payment_method_id]`,
    },
  ].forEach(({ value, name }) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', name);
    input.setAttribute('value', value);
    form.appendChild(input);
  });
});

forward({
  from: initSyfPaymentMethod,
  to: getSyfCheckoutTokenFx,
});

forward({
  from: completeSyfPayment,
  to: completeSyfPaymentFx,
});

sample({
  clock: completeSyfPaymentFx.doneData,
  fn: data => {
    window.removeEventListener('message', modalClosureHandler);

    return data;
  },
  target: goToLocationFx,
});

forward({
  from: [completeSyfPaymentFx.failData, completeSyfPaymentFx.doneData],
  to: initSyfPaymentMethod,
});

sample({
  clock: getSyfCheckoutTokenFx.doneData,
  target: $syfData,
});

forward({
  from: initStripe,
  to: initStripeFx,
});

forward({
  from: loadAfterpayJs,
  to: loadAfterpayJsFx,
});

sample({
  clock: initStripeFx.done,
  source: $stripe,
  target: initStripeElementsFx,
});

sample({
  clock: initStripeFx.done,
  source: orderStores.$order,
  target: initGoogleAndAppleElementsFx,
});

forward({
  from: initGoogleAndAppleElementsFx.done,
  to: initGoogleAndAppleConfigFx,
});

sample({
  clock: createTokenFx.doneData,
  filter: ({ error }) => error,
  fn: ({ error: { message } }) => message,
  target: showCardNumberErrorFx,
});

sample({
  clock: createTokenFx.doneData,
  filter: ({ error }) => !error,
  source: $paymentId,
  fn: (paymentId, { token }) => ({ paymentId, token }),
  target: createFormToSendFx,
});

sample({
  clock: createFormToSendFx.doneData,
  fn: () => true,
  target: [
    createEffect().use(() =>
      document.getElementById('checkout_form_payment').submit(),
    ),
    $isPurchasing,
  ],
});

forward({
  from: initSyfPaymentMethod,
  to: subsrctibeToSynchronysEventsFx,
});

sample({
  clock: purchaseWithStripe,
  source: {
    $addressFormValues: addressStores.$shipAddressFormValues,
    $stripeElements,
    $stripe,
  },
  fn: ({ $addressFormValues, $stripeElements, $stripe }) => ({
    cardNumberElement: $stripeElements.cardNumber,
    options: {
      name: ['firstname', 'lastname'].map(i => $addressFormValues[i]).join(' '),
      address_line1: $addressFormValues.address1,
      address_line2: $addressFormValues.address2,
      address_city: $addressFormValues.city,
      address_state: $addressFormValues.state_abbr,
      address_country: 'US',
      address_zip: $addressFormValues.zipcode,
    },
    stripe: $stripe,
  }),
  target: createTokenFx,
});

export const $isAvailablePayPair = orderStores.$order.map(
  order =>
    order.total_number >= 150.0 &&
    !['MN', 'NJ'].includes(order.ship_address?.state_name),
);

export const $isAvailableSynchrony = orderStores.$order.map(
  order =>
    order.total_number >= 199.0 &&
    order.line_items.some(
      item =>
        item.brand_name === 'Continental' || item.brand_name === 'General',
    ),
);

export const $isAvailableGoogleOrApplePay = $paymentFlowType.map(
  paymentData => paymentData.applePay || paymentData.googlePay,
);

const $syfPaymentModalData = combine(
  orderStores.$order,
  $syfData,
  addressStores.$shipAddressFormValues,
  ({ total_number, email }, syfData, shipAddress) => ({
    ...syfData,
    transAmount1: String(total_number),
    processInd: '3',
    clientTransId: uuidv4().slice(0, 30),
    custAddress1: shipAddress.address1,
    custZipCode: shipAddress.zipcode,
    phoneNumber: shipAddress.phone,
    emailAddress1: email,
    phoneNumber: shipAddress.phone,
    custCity: shipAddress.city,
    custFirstName: shipAddress.firstname,
    custLastName: shipAddress.lastname,
    custState: shipAddress.state_abbr,
  }),
);

const $isSyfLoading = combine(
  [getSyfCheckoutTokenFx.pending, completeSyfPaymentFx.pending],
  loadingStates => loadingStates.some(Boolean),
);

forward({
  from: closePaypairModal,
  to: closePaypairModalFx,
});

forward({
  from: orderActions.notifyOfInconsistensy,
  to: [orderActions.getOrder, closePaypairModal],
});

export const actions = {
  initStripe,
  purchaseWithStripe,
  initPaypair,
  initAffirm,
  initSyfPaymentMethod,
  getSyfCheckoutTokenFx,
  completeSyfPayment,
  completeSyfPaymentFx,
  initGoogleAndAppleConfigFx,
  initPaypairFx,
  loadAfterpayJs,
  onPaypairEventFired,
  selectStripe,
  selectPaypal,
  selectCashapp,
};

export const stores = {
  $stripe,
  $stripeElements,
  $isPurchasing,
  $isAvailablePayPair,
  $isAvailableSynchrony,
  $syfData,
  $syfPaymentModalData,
  $isSyfLoading,
  $paymentFlowType,
  $isAvailableGoogleOrApplePay,
  $afterpayJsLoaded,
};

export const store = combine(stores);
